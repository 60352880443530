<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="citizenDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Contact Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeCitizenDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-expansion-panels accordion multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>Referral Data</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      ref="referralDate"
                      v-model="referralDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateFormattedDisplay"
                          label="Referral Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ><vue-fontawesome
                            :icon="['fas', 'calendar-alt']"
                            slot="prepend-inner"
                            class="mt-1 mr-1"
                          />{{ dateFormatted }}</v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="dateFormatted"
                        @change="save"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="referralDate = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.referralDate.save(referralDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="citizenEdit.referralData.referringAgency"
                      id="referringAgency"
                      name="referringAgency"
                      label="Referring Agency"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="citizenEdit.referralData.firstName"
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="citizenEdit.referralData.lastName"
                      :rules="rules"
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="citizenEdit.referralData.phone"
                      v-mask="'(###) ###-####'"
                      id="phone"
                      name="phone"
                      label="Phone"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      ref="eligibleDate"
                      v-model="eligibleDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="eligibleDateFormattedDisplay"
                          label="Referral Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          ><vue-fontawesome
                            :icon="['fas', 'calendar-alt']"
                            slot="prepend-inner"
                            class="mt-1 mr-1"
                          />{{ eligibleDateFormatted }}</v-text-field
                        >
                      </template>
                      <v-date-picker
                        v-model="eligibleDateFormatted"
                        @change="saveEligible"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="eligibleDate = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.eligibleDate.save(eligibleDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      v-model="citizenEdit.referralData.isEligible"
                      :label="isEligibleLabel"
                      id="isEligible"
                      name="isEligible"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.referralData.ineligibleReason"
                      id="ineligibleReason"
                      name="ineligibleReason"
                      label="Reason for Ineligiblity"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.referralData.declinedReason"
                      id="declinedReason"
                      name="declinedReason"
                      label="Reason for Refferal Decline"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeCitizenDialog"> Close </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            class="mr-4"
            @click="saveCitizen"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-row">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editCitizen()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right" :width="leftColWidth">Referral Date</td>
              <td class="font-weight-bold">
                {{ formatDate(citizen.referralData.referralDate) }}
              </td>
            </tr>
            <tr>
              <td class="text-right" align="top" :width="leftColWidth">
                Referred By
              </td>
              <td class="font-weight-bold">
                {{
                  citizen.referralData.firstName +
                    ' ' +
                    citizen.referralData.lastName
                }}
                <span
                  v-if="citizen.referralData.phone"
                  class="font-weight-regular"
                >
                  {{ citizen.referralData.phone }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-right" align="top" :width="leftColWidth">
                Agency
              </td>
              <td class="font-weight-bold">
                {{ citizen.referralData.referringAgency }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right text-no-wrap" :width="leftColWidth">
                Eligible/Ineligible Date
              </td>
              <td class="font-weight-bold">
                {{ formatDate(citizen.referralData.dateEligible) }}
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="rightColWidth">
                Eligible to Participate
              </td>
              <td class="font-weight-bold">
                <vue-fontawesome
                  v-if="citizen.referralData.isEligible"
                  :icon="['far', 'check']"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" />
              </td>
            </tr>
            <tr class="atop">
              <td class="text-right" :width="rightColWidth">
                Ineligible/Declined
              </td>
              <td>
                <div v-if="citizen.referralData.ineligibleReason">
                  Ineligible: <b>{{ citizen.referralData.ineligibleReason }}</b>
                </div>
                <div v-if="citizen.referralData.declinedReason">
                  Referral Declined:
                  <b>{{ citizen.referralData.declinedReason }}</b>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CitizenService from '@/services/CitizenService'
import ActivityService from '@/services/ActivityService'
import options from '@/config/valueOptions'
import states from '@/config/states'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: { Snackbar },
  name: 'Referral',
  props: {
    citizen: Object,
    agencyAbbr: String,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      panel: [0],
      referralDate: '',
      referralDateMenu: false,
      eligibleDate: '',
      isEligibleLabel: options.isEligible,
      states: states,
      leftColWidth: 120,
      rightColWidth: 160,
      dateFormatted: '',
      dateFormattedDisplay: '',
      eligibleDateFormatted: '',
      eligibleDateFormattedDisplay: '',
      tab: null,
      dialog: false,
      citizenDialog: false,
      citizenDialogDelete: false,
      citizenIndex: -1,
      citizenId: this.$route.params.id,
      citizenEdit: {
        referralData: {
          referralDate: null
        }
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      headers: [
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          align: 'start',
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
      // activities: activities
    }
  },
  watch: {
    citizenDialog(val) {
      // this.isEdit
      val || this.closeCitizenDialog()
    },
    citizenDialogDelete(val) {
      val || this.closeCitizenDelete()
    },
    date(val) {
      if (val) {
        // this.dateFormatted = this.formatDate(
        //   this.citizenEdit.referralData.referralDate
        // )
        this.dateFormatted = this.formatDate(this.date)
      }
    }
  },
  mounted() {
    // this.dateFormatted =
    // const dt = DateTime.now()
    // // const str = dt.toISOString()
    // console.log(dt)
    // this.citizen.dateOfBirth = this.citizen.dateOfBirth.substring(0, 10)
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    dobFormatted() {
      const dt = DateTime.now()
      // const str = dt.toISOString()
      // console.log(str)
      return dt.toISOString()
      // return this.citizen.dateOfBirth.substring(0, 10)
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    }
    // agencyAbbr() {
    //   return this.$store.state.user.agency.abbr || null
    // }
  },
  methods: {
    save(date) {
      // this.$refs.referralDate.save(date)
      this.dateFormattedDisplay = this.formatDate(date)
    },
    saveEligible(date) {
      // this.$refs.eligibleDate.save(date)
      this.eligibleDateFormattedDisplay = this.formatDate(date)
    },
    formatDate(date) {
      // console.log(date)
      if (!date) return null
      // const dt = DateTime.fromJSDate(date)
      // const dt = DateTime.utc(date)
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      // console.log(dt)
      return dt
    },
    formatDatePicker(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    editCitizen() {
      // alert(id)
      // this.citizenIndex = this.citizens.indexOf(item)
      // this.citizen = Object.assign({}, item)
      // this.citizenId = id
      this.dateFormatted = this.formatDatePicker(
        this.citizen.referralData.referralDate
      )
      this.dateFormattedDisplay = this.formatDate(
        this.citizen.referralData.referralDate
      )
      this.eligibleDateFormatted = this.formatDatePicker(
        this.citizen.referralData.dateEligible
      )
      this.eligibleDateFormattedDisplay = this.formatDate(
        this.citizen.referralData.dateEligible
      )
      this.citizenEdit = Object.assign({}, this.citizen)
      this.citizenDialog = true
      // var dt = DateTime.fromISO(this.citizen.dateOfBirth)
      // const str = dt.toISODate()
      // console.log(str)
      // this.dateFormattedDisplay = str
      // console.log(this.citizen)
    },
    deleteCitizen(item, id) {
      // alert(id)
      this.citizenIndex = this.citizens.indexOf(item)
      this.citizen = Object.assign({}, item)
      this.citizenId = id
      this.citizenDialogDelete = true
    },
    // deleteCitizenConfirm() {
    //   const id = this.citizenId
    //   // alert(id)
    //   CitizenService.delete(id)
    //     .then(response => {
    //       console.log(response)
    //       this.citizens.splice(this.citizenIndex, 1)
    //       this.closeCitizenDelete()
    //     })
    //     .catch(e => {
    //       console.log(e)
    //     })
    // },
    async saveCitizen() {
      // console.log('date: ' + this.dateFormatted)
      // alert('component')
      const data = {
        referralData: {
          referralDate: this.dateFormatted,
          firstName: this.citizenEdit.referralData.firstName,
          lastName: this.citizenEdit.referralData.lastName,
          phone: this.citizenEdit.referralData.phone,
          referringAgency: this.citizenEdit.referralData.referringAgency,
          dateEligible: this.eligibleDateFormatted,
          isEligible: this.citizenEdit.referralData.isEligible,
          ineligibleReason: this.citizenEdit.referralData.ineligibleReason,
          declinedReason: this.citizenEdit.referralData.declinedReason
        }
      }
      try {
        const citizenUpdate = await CitizenService.update(this.citizen.id, data)
        // console.log(citizenUpdate)
        if (citizenUpdate.status != 200) {
          return
        }
        // since it worked, send activity log
        const activityData = {
          action: 'Updated referral details',
          agency: this.$store.state.agency.id,
          citizen: this.citizen.id,
          citizenName:
            this.citizenEdit.firstName + ' ' + this.citizenEdit.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }
        Object.assign(this.citizen, this.citizenEdit)
        this.citizen.referralData.referralDate = this.dateFormatted
        this.citizen.referralData.dateEligible = this.eligibleDateFormatted
        this.closeCitizenDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Citizen Contact Info updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Citizen problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeCitizenDialog() {
      this.citizenDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   // this.citizenIndex = -1
      // })
    },
    closeCitizenDelete() {
      this.citizenDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
