<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="participationDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>New Participation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeParticipationDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="doc.description"
                  id="description"
                  name="description"
                  label="Description (Optional)"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="referralDate"
                  v-model="referralDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="referralDateMDY"
                      label="Referral Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ><vue-fontawesome
                        :icon="['fas', 'calendar-alt']"
                        slot="prepend-inner"
                        class="mt-1 mr-1"
                      />{{ referralDateYMD }}</v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="referralDateYMD"
                    @change="saveDate(referralDateYMD, 'referral')"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="referralDate = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.referralDate.save(referralDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="intakeDate"
                  v-model="intakeDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="intakeDateMDY"
                      label="Intake Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ><vue-fontawesome
                        :icon="['fas', 'calendar-alt']"
                        slot="prepend-inner"
                        class="mt-1 mr-1"
                      />{{ intakeDateYMD }}</v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="intakeDateYMD"
                    @change="saveDate(intakeDateYMD, 'intake')"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="intakeDate = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.intakeDate.save(intakeDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="startDate"
                  v-model="startDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateMDY"
                      label="Participation Start Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ><vue-fontawesome
                        :icon="['fas', 'calendar-alt']"
                        slot="prepend-inner"
                        class="mt-1 mr-1"
                      />{{ startDateYMD }}</v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="startDateYMD"
                    @change="saveDate(startDateYMD, 'start')"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDate = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDate.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="endDate"
                  v-model="endDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDateMDY"
                      label="Participation End Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      ><vue-fontawesome
                        :icon="['fas', 'calendar-alt']"
                        slot="prepend-inner"
                        class="mt-1 mr-1"
                      />{{ endDateYMD }}</v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="endDateYMD"
                    @change="saveDate(endDateYMD, 'end')"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDate = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDate.save(endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="doc.referralData.reasonEnded"
                  @change="clearDismissal"
                  :items="participationEndReasons"
                  label="Reason Ended Participation"
                  hide-details="auto"
                  :rules="rules"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="doc.referralData.reasonForDismissal"
                  @change="clearDismissalOther"
                  :items="reasonForDismissalOptions"
                  :disabled="doc.referralData.reasonEnded != 'Dismissed'"
                  label="Reason For Dismissal"
                  hide-details="auto"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="doc.referralData.reasonForDismissalOther"
                  :disabled="doc.referralData.reasonForDismissal != 'Other'"
                  id="reasonForDismissalOther"
                  name="reasonForDismissalOther"
                  label="Other reason for dismissal"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeParticipationDialog"> Close </v-btn>
          <v-btn
            elevation="0"
            :class="valid ? 'white--text primary-btn' : 'grey--text'"
            :disabled="!valid"
            @click="saveDoc"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      elevation="0"
      dark
      class="primary-btn"
      @click="participationDialog = true"
      ><vue-fontawesome
        :icon="['far', 'plus']"
        class="mr-1"
      />Participation</v-btn
    >
  </div>
</template>

<script>
// import ParticipationService from '@/services/ParticipationService'
import Snackbar from '@/components/Snackbar.vue'
import options from '@/config/valueOptions'
import ParticipationService from '@/services/ParticipationService'
import ActivityService from '@/services/ActivityService'
import { DateTime } from 'luxon'

export default {
  name: 'NewParticipation',
  components: {
    Snackbar
  },
  props: {
    citizen: Object
  },
  data() {
    return {
      panel: [0],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      valid: false,
      doc: {
        description: '',
        referralData: {
          referralDate: '',
          intakeDate: '',
          startDate: '',
          endDate: '',
          enrollmentDate: '',
          orientationDate: '',
          reasonEnded: '',
          reasonForDismissal: '',
          reasonForDismissalOther: '',
          intakeStaff: '',
          qaReviewStaff: '',
          qaReviewDate: ''
        }
      },
      participationEndReasons: options.participationEndReasons,
      reasonForDismissalOptions: options.reasonForDismissalOptions,
      participationDialog: false,
      referralDate: '',
      referralDateYMD: '',
      referralDateMDY: '',
      intakeDate: '',
      intakeDateYMD: '',
      intakeDateMDY: '',
      startDate: '',
      startDateYMD: '',
      startDateMDY: '',
      endDate: '',
      endDateYMD: '',
      endDateMDY: '',
      rules: [v => !!v || 'This is required']
    }
  },
  watch: {
    participationDialog(val) {
      val || this.closeParticipationDialog()
    },
    participationDialogDelete(val) {
      val || this.closeParticipationDelete()
    }
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.citizenIndex === -1 ? 'New Citizen' : 'Edit Citizen'
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    },
    agencyAbbr() {
      return this.$store.state.user.agency.abbr || null
    }
  },
  methods: {
    reset() {
      this.valid = false
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validateForm() {
      this.$refs.form.validate()
    },
    closeParticipationDialog() {
      this.participationDialog = false
    },
    saveDate(date, ref) {
      console.log(ref)
      if (ref === 'referral') {
        this.referralDateMDY = this.formatDate(date)
      } else if (ref === 'intake') {
        this.intakeDateMDY = this.formatDate(date)
      } else if (ref === 'start') {
        this.startDateMDY = this.formatDate(date)
      } else {
        this.endDateMDY = this.formatDate(date)
      }
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateYMD(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    clearDismissal() {
      if (this.doc.referralData.reasonEnded != 'Dismissed') {
        this.doc.referralData.reasonForDismissal = null
        this.doc.referralData.reasonForDismissalOther = null
      }
    },
    clearDismissalOther() {
      if (this.doc.referralData.reasonForDismissalOther != 'Other') {
        this.doc.referralData.reasonForDismissalOther = null
      }
    },
    async saveDoc() {
      // console.log('date: ' + this.dateFormatted)
      // alert('component')
      const data = {
        citizen: this.citizen.id,
        createdBy: this.$store.state.user.id,
        agency: this.$store.state.agency.id,
        agencyAbbr: this.$store.state.agency.abbr,
        description: this.doc.description,
        referralData: {
          referralDate: this.referralDateYMD,
          intakeDate: this.intakeDateYMD,
          startDate: this.startDateYMD,
          endDate: this.endDateYMD,
          reasonEnded: this.doc.referralData.reasonEnded,
          reasonForDismissal: this.doc.referralData.reasonForDismissal,
          reasonForDismissalOther: this.doc.referralData.reasonForDismissalOther
        }
      }
      try {
        const participationResult = await ParticipationService.create(data)
        // console.log(participationUpdate)
        if (participationResult.status != 200) {
          return
        }
        /*
          activity log - since it worked, send activity
        */
        const activityData = {
          action: 'Created participation',
          agency: this.$store.state.agency.id,
          citizen: this.citizen.id,
          citizenName: this.citizen.firstName + ' ' + this.citizen.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }
        this.$router.push('/participation/' + participationResult.data.id)

        // this.snackbar.model = true
        // this.snackbar.message = 'Participation Contact Info updated'
        // this.snackbar.color = 'green'
        // this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Cannot create participation.'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
