<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row>
      <v-col cols="12">
        <h2 class="font-weight-black citizen-header">
          {{ citizen.firstName + ' ' + citizen.lastName }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"><h3 class="font-weight-light">Profile</h3></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels multiple v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Contact Details</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <contact-detail :citizen="citizen" :canEdit="canEdit" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Demographics</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <demographics :citizen="citizen" :canEdit="canEdit" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Referral</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <referral :citizen="citizen" :canEdit="canEdit" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="">
              <v-row no-gutters>
                <v-col cols="12">Log</v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <citizen-log :citizen="citizen.id" ref="citizenLog" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right caption text--secondary">
        Created by
        <b>{{ citizen.createdBy.fullName }}</b>
        on <b> {{ formatDateTime(citizen.createdAt) }}</b>
        <v-divider vertical class="mx-4"></v-divider>
        Updated by
        <b>{{ citizen.updatedBy.fullName }}</b>
        on <b> {{ formatDateTime(citizen.updatedAt) }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- <div class="hr-sect">Participation</div> -->
        <h3 class="font-weight-light">Participation</h3>
      </v-col>
      <v-col cols="12">
        <new-participation :citizen="citizen" />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="participationHeaders"
          :items="participations"
          :sort-by="['updatedAt']"
          :sort-desc="[true]"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="participationDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete Participation?</span
                  >
                </v-card-title>
                <v-card-text>
                  This participation for <b>{{ agencyName }}</b> will be
                  permenantly deleted.
                  <!-- <div class="text--disabled body-2">
                    {{ agencyName }}
                  </div> -->
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="participationDialogDelete = false">
                    No
                  </v-btn>
                  <v-btn
                    dark
                    text
                    @click="deleteParticipationConfirm"
                    class="primary-btn"
                  >
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.agency.name="{ item }">
            <b>{{ item.agency.name }}</b>
          </template>
          <template v-slot:item.createdBy="{ item }">
            {{ item.createdBy.firstName + ' ' + item.createdBy.lastName }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:item.updatedAt="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- {{ item.agency }} -->
            <router-link
              v-if="isAdmin"
              :to="{ path: '/participation/' + item.id }"
            >
              <v-btn icon small>
                <vue-fontawesome small :icon="['fas', 'pencil-alt']" />
              </v-btn>
            </router-link>
            <router-link
              v-else-if="item.agency._id == $store.state.agency.id"
              :to="{ path: '/participation/' + item.id }"
            >
              <v-btn icon small>
                <vue-fontawesome small :icon="['fas', 'pencil-alt']" />
              </v-btn>
            </router-link>
            <v-btn
              v-if="canDelete"
              icon
              small
              @click="deleteParticipation(item)"
            >
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
            <!-- <v-btn v-if="isAdmin" icon small @click="deleteParticipation(item)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CitizenService from '@/services/CitizenService'
import ParticipationService from '@/services/ParticipationService'
import options from '@/config/valueOptions.json'
import Demographics from '@/components/Demographics'
import ContactDetail from '@/components/ContactDetail'
import NewParticipation from '@/components/participation/NewParticipation'
import Referral from '@/components/Referral.vue'
import CitizenLog from '@/components/CitizenLog.vue'
import Snackbar from '@/components/Snackbar.vue'
import ActivityService from '@/services/ActivityService'
import { DateTime } from 'luxon'

export default {
  name: 'Citizen',
  components: {
    Snackbar,
    Demographics,
    ContactDetail,
    Referral,
    CitizenLog,
    NewParticipation
  },
  data() {
    return {
      panel: [],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      // snackbarModel: false,
      // snackbarColor: '',
      // snackbarMessage: '',
      // snackbarIcon: ['fas', 'exclamation-triangle'],
      // snackbarTimeout: 3000,
      participations: [],
      dateMenu: false,
      tab: null,
      dialog: false,
      participationDialog: false,
      participationDialogDelete: false,
      participationIndex: -1,
      participationId: '',
      agencyName: '',
      citizenId: this.$route.params.id,
      // citizen: {},
      citizen: {
        createdBy: '',
        updatedBy: '',
        sid: '',
        gdc: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        gender: '',
        race: {
          label: '',
          other: ''
        },
        address: {
          facility: ''
        },
        veteran: {
          isVet: false
        },
        language: {},
        emergencyContact: {
          relation: '',
          firstName: '',
          lastName: '',
          mobilePhone: '',
          email: ''
        },
        housing: {
          status: '',
          other: ''
        },
        employment: {
          status: '',
          student: '',
          employerName: '',
          employerCity: '',
          employerState: '',
          hireDate: null,
          endDate: null,
          wage: '',
          unemploymentBenefits: false
        },
        subsidies: {
          ssi: false,
          ssdi: false,
          medicaid: false,
          medicare: false,
          veterans: false,
          foodStamps: false,
          temporaryNeedyFamilies: false,
          welfare: false,
          other: ''
        },
        referralData: {
          referralDate: null,
          firstName: '',
          lastName: '',
          phone: '',
          referringAgency: '',
          dateEligible: '',
          isEligible: false,
          ineligibleReason: '',
          declinedReason: ''
        }
      },
      rules: [v => !!v || 'This is required'],
      genderOptions: ['Male', 'Female'],
      raceOptions: options.race,
      ethnicityLabel: options.ethnicity,
      participationHeaders: [
        {
          text: 'Agency',
          align: 'start',
          filterable: true,
          value: 'agency.name'
        },
        {
          text: 'Description',
          align: 'start',
          filterable: true,
          value: 'description'
        },
        {
          text: 'Created By',
          align: 'start',
          filterable: true,
          value: 'createdBy'
        },
        {
          text: 'Created',
          align: 'start',
          filterable: true,
          value: 'createdAt'
        },
        {
          text: 'Updated',
          align: 'start',
          filterable: true,
          value: 'updatedAt'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
      // activities: activities
    }
  },
  watch: {
    participationDialog(val) {
      // this.isEdit
      val || this.closeParticipationDialog()
    },
    participationDialogDelete(val) {
      val || this.closeParticipationDelete()
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  created() {
    CitizenService.get(this.$route.params.id)
      .then(response => {
        this.citizen = response.data
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
    ParticipationService.getByCitizen(this.$route.params.id)
      // ParticipationService.getByAgency(
      //   this.$route.params.id,
      //   this.$store.state.agency.id
      // )
      .then(response => {
        this.participations = response.data
        // console.log(response.data[0])
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
  },
  // mounted() {
  //   this.$root.$on('updateActivity', data => {
  //     console.log(data)
  //   })
  // },
  computed: {
    isAdmin() {
      return this.$store.state.user.isAdmin || false
    },
    canDelete() {
      return this.$store.state.user.agency.abbr == 'cor' || this.isAdmin
        ? true
        : false
    },
    canEdit() {
      return this.$store.state.user.agency.abbr == 'ars' ||
        this.$store.state.user.agency.abbr == 'cor' ||
        this.isAdmin
        ? true
        : false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.participationIndex === -1 ? 'New Citizen' : 'Edit Citizen'
    },
    isEdit() {
      return this.participationIndex === -1 ? false : true
    },
    agencyAbbr() {
      return this.$store.state.agency.abbr || null
    }
  },
  methods: {
    save(date) {
      this.$refs.dateMenu.save(date)
    },
    // updateActivity(activity) {
    //   this.participations.push(activity)
    // },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toLocal()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateTime(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toLocal()
        .toFormat('MM/dd/yyyy hh:mm a')
      return dt
    },
    // editCitizen() {
    //   // alert(id)
    //   // this.participationIndex = this.citizens.indexOf(item)
    //   // this.citizen = Object.assign({}, item)
    //   // this.citizenId = id
    //   this.citizenEdit = Object.assign({}, this.citizen)
    //   this.participationDialog = true
    //   // console.log(this.citizen)
    // },
    deleteParticipation(item) {
      // alert(id)
      this.participationIndex = this.participations.indexOf(item)
      // this.citizen = Object.assign({}, item)
      this.agencyName = item.agency.name
      this.participationId = item.id
      this.participationDialogDelete = true
    },
    // refreshActivityList() {
    //   // update log
    //   this.$refs.citizenLog.refreshActivityList()
    // },
    async deleteParticipationConfirm() {
      const id = this.participationId
      // alert(id)
      try {
        const particpationReponse = await ParticipationService.delete(id)
        if (!particpationReponse) {
          return
        }
        // console.log(particpationReponse)
        // since it worked, send activity log
        const activityData = {
          action: 'Deleted participation',
          agency: this.$store.state.agency.id,
          citizen: this.citizen.id,
          citizenName: this.citizen.firstName + ' ' + this.citizen.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }
        // update log
        // this.$refs.citizenLog.refreshActivityList()

        this.participations.splice(this.participationIndex, 1)
        this.closeParticipationDelete()
        this.snackbar.model = true
        this.snackbar.message = 'Participation deleted'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (error) {
        this.closeParticipationDelete()
        this.snackbar.model = true
        this.snackbar.message = 'Unable to delete participation!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(error)
      }
    },
    async saveCitizen() {
      // alert('view')
      const data = {
        firstName: this.citizenEdit.firstName,
        middleName: this.citizenEdit.middleName,
        lastName: this.citizenEdit.lastName,
        gender: this.citizenEdit.gender,
        sid: this.citizenEdit.sid,
        gdc: this.citizenEdit.gdc,
        race: {
          label: this.citizenEdit.race.label,
          other: this.citizenEdit.race.other
        },
        ethnicity: this.citizenEdit.ethnicity,
        emergencyContact: {
          relation: this.citizenEdit.emergencyContact.relation,
          firstName: this.citizenEdit.emergencyContact.firstName,
          lastName: this.citizenEdit.emergencyContact.lastName,
          mobilePhone: this.citizenEdit.emergencyContact.mobilePhone,
          email: this.citizenEdit.emergencyContact.email
        }
      }
      try {
        const citizenUpdate = await CitizenService.update(this.citizen.id, data)
        // console.log(citizenUpdate)
        if (citizenUpdate.status != 200) {
          return
        }
        Object.assign(this.citizen, this.citizenEdit)
        this.closeParticipationDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Citizen updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Citizen problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeParticipationDialog() {
      this.participationDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   this.participationIndex = -1
      // })
    },
    closeParticipationDelete() {
      this.participationDialogDelete = false
      this.$nextTick(() => {
        // this.citizen = Object.assign({}, this.citizenDefault)
        this.participationIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
.hr-sect {
  font-weight: 500;
  display: flex;
  flex-basis: 100%;
  align-items: center;
  // color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;

  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.25);
    height: 1px;
    font-size: 0;
    line-height: 0;
    margin: 0 8px;
  }
}
</style>
