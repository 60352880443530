<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="citizenDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Demographics</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeCitizenDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header>General</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="citizenEdit.sid"
                        :rules="rules"
                        id="sid"
                        name="sid"
                        label="State Identification Number (SID)"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="citizenEdit.gdc"
                        :rules="rules"
                        id="gdc"
                        name="gdc"
                        label="GDC"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateFormattedDisplay"
                            label="Birth date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ><vue-fontawesome
                              :icon="['fas', 'calendar-alt']"
                              slot="prepend-inner"
                              class="mt-1 mr-1"
                            />{{
                              formatDatePicker(citizen.dateOfBirth)
                            }}</v-text-field
                          >
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="dateFormatted"
                          min="1920-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="citizenEdit.gender"
                        :items="genderOptions"
                        label="Gender"
                        hide-details="auto"
                        :rules="rules"
                        ><vue-fontawesome
                          :icon="['far', 'venus-mars']"
                          color="grey"
                          slot="prepend"
                          class="mt-1"
                        />
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="citizenEdit.maritalStatus"
                        :items="maritalStatusOptions"
                        label="Marital Status"
                        hide-details="auto"
                        :rules="rules"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Race + Language</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="citizenEdit.race.label"
                        :items="raceOptions"
                        label="Race"
                        hide-details="auto"
                        :rules="rules"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="citizenEdit.race.other"
                        id="raceOther"
                        name="raceOther"
                        label="Other"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-checkbox
                        v-model="citizenEdit.ethnicity"
                        :label="ethnicityLabel"
                        id="ethicity"
                        name="ethicity"
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="citizenEdit.language.primaryEnglish"
                        label="English Primary Language"
                        id="primaryEnglish"
                        name="primaryEnglish"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="citizenEdit.language.other"
                        id="other"
                        name="other"
                        label="Other"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Veteran + Disibility</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="citizenEdit.approvedDisability"
                        :label="approvedDisabilityLabel"
                        id="ethicity"
                        name="ethicity"
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-checkbox
                        v-model="citizenEdit.veteran.isVet"
                        label="Veteran"
                        id="isVet"
                        name="isVet"
                        hide-details="auto"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-checkbox
                        v-model="citizenEdit.veteran.activeDuty"
                        label="Active Duty"
                        id="activeDuty"
                        name="activeDuty"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="citizenEdit.veteran.branchOfService"
                        id="branchOfService"
                        name="branchOfService"
                        label="Service Branch"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="citizenEdit.numberOfDependents"
                        id="numberOfDependents"
                        name="numberOfDependents"
                        label="Dependents"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >Housing + Children</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="2">
                      <v-text-field
                        v-model="citizenEdit.numberCustodyChildren"
                        id="numberCustodyChildren"
                        name="numberCustodyChildren"
                        label="Custody/Children"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="8"> </v-col>
                    <v-col cols="12">
                      <v-select
                        clearable
                        v-model="citizenEdit.housing.status"
                        :items="housingOptions"
                        label="Housing"
                        hide-details="auto"
                        :rules="rules"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="citizenEdit.housing.other"
                        label="Housing Other"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Employment</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        clearable
                        v-model="citizenEdit.employment.status"
                        :items="employmentOptions"
                        label="Employment"
                        hide-details="auto"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        clearable
                        v-model="citizenEdit.employment.student"
                        :items="studentOptions"
                        label="Student"
                        hide-details="auto"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="citizenEdit.employment.employerName"
                        id="employerName"
                        name="employerName"
                        label="Employer Name"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="citizenEdit.employment.employerCity"
                        id="numberCustodyChildren"
                        name="numberCustodyChildren"
                        label="City"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        outlined
                        v-model="citizenEdit.employment.employerState"
                        :items="states"
                        item-value="abbreviation"
                        item-text="name"
                        label="State"
                        hide-details="auto"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu
                        ref="hireDate"
                        v-model="hireDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="hireDateFormattedDisplay"
                            label="Start Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ><vue-fontawesome
                              :icon="['fas', 'calendar-alt']"
                              slot="prepend-inner"
                              class="mt-1 mr-1"
                            />{{ hireDateFormatted }}</v-text-field
                          >
                        </template>
                        <v-date-picker
                          v-model="hireDateFormatted"
                          @change="saveHire"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="hireDate = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.hireDate.save(hireDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu
                        ref="endDate"
                        v-model="endDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="endDateFormattedDisplay"
                            label="End Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ><vue-fontawesome
                              :icon="['fas', 'calendar-alt']"
                              slot="prepend-inner"
                              class="mt-1 mr-1"
                            />{{ endDateFormatted }}</v-text-field
                          >
                        </template>
                        <v-date-picker
                          v-model="endDateFormatted"
                          @change="saveEnd"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="endDate = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.endDate.save(endDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Subsidies</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="2"
                      md="6"
                      lg="6"
                      v-for="(subsidy, index) in subsidies"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="citizenEdit.subsidies[subsidy.id]"
                        :value="subsidy.id ? true : false"
                        :label="subsidy.label"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        clearable
                        v-model="citizenEdit.subsidies.other"
                        label="Other Subsidies"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6"> </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeCitizenDialog"> Close </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            class="mr-4"
            @click="saveCitizen"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-row">
        <v-btn
          v-if="canEdit"
          small
          dark
          elevation="0"
          @click="editCitizen()"
          class="primary-btn"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right text-no-wrap" :width="colWidth">Age</td>
              <td class="font-weight-bold">
                {{ age(citizen.dateOfBirth) }}
                <!-- {{ formatDate(citizen.dateOfBirth) }} -->
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="colWidth">Gender</td>
              <td class="font-weight-bold">{{ citizen.gender }}</td>
            </tr>
            <tr>
              <td class="text-right" align="top" :width="colWidth">Race</td>
              <td class="font-weight-bold">
                {{ citizen.race.label }}
                <span
                  v-if="citizen.race.other"
                  class="text--secondary font-weight-regular"
                >
                  ({{ citizen.race.other }})
                  <div v-if="citizen.ethnicity">
                    {{ ethnicityLabel }}
                    <vue-fontawesome :icon="['far', 'check']" class="ml-1" />
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="colWidth">
                Marital Status
              </td>
              <td class="font-weight-bold">{{ citizen.maritalStatus }}</td>
            </tr>
            <tr>
              <td class="text-right" :width="colWidth">
                Disability
              </td>
              <td class="font-weight-bold">
                <span v-if="citizen.approvedDisability">
                  {{ approvedDisabilityLabel }}
                  <vue-fontawesome :icon="['far', 'check']" class="ml-1"
                /></span>
                <span v-else>None</span>
              </td>
            </tr>
            <tr>
              <td class="text-right text-no-wrap" :width="colWidth">
                Veteran
              </td>
              <td>
                <vue-fontawesome
                  v-if="citizen.veteran.isVet"
                  :icon="['far', 'check']"
                  class="ml-1"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-1" />
                <span
                  v-if="citizen.veteran.isVet && citizen.veteran.activeDuty"
                  class="ml-3"
                >
                  Active Duty
                  <vue-fontawesome :icon="['far', 'check']" class="ml-1" />
                </span>
                <span
                  v-if="
                    citizen.veteran.isVet && citizen.veteran.branchOfService
                  "
                  class="ml-3"
                >
                  Service: <b>{{ citizen.veteran.branchOfService }}</b>
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="colWidth">Language</td>
              <td>
                English is primary langage
                <vue-fontawesome
                  v-if="citizen.language.primaryEnglish"
                  :icon="['far', 'check']"
                  class="ml-1"
                />
                <vue-fontawesome v-else :icon="['far', 'ban']" class="ml-1" />
                <div v-if="citizen.language.other">
                  Other: <b>{{ citizen.language.other }}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="colWidth">Dependents</td>
              <td>
                <div v-if="citizen.numberOfDependents">
                  <b>{{ citizen.numberOfDependents }}</b>
                </div>
                <div v-else>
                  <vue-fontawesome :icon="['far', 'minus']" class="ml-1" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="colWidth">Custody/Children</td>
              <td>
                <div v-if="citizen.numberCustodyChildren">
                  <b>{{ citizen.numberCustodyChildren }}</b>
                </div>
                <div v-else>
                  <vue-fontawesome :icon="['far', 'minus']" class="ml-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <v-simple-table>
          <thead></thead>
          <tbody>
            <tr>
              <td class="text-right" :width="colWidth">Housing</td>
              <td>
                <b>{{ citizen.housing.status }}</b>
                <span v-if="citizen.housing.other" class="ml-2">
                  {{ citizen.housing.other }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="colWidth">Employment Status</td>
              <td>
                <b>{{ citizen.employment.status }}</b>
                <div v-if="citizen.employment.student">
                  Student <b>{{ citizen.employment.student }}</b>
                </div>
              </td>
            </tr>
            <tr class="atop">
              <td class="text-right" :width="colWidth">Employment History</td>
              <td>
                <b>{{ citizen.employment.employerName }}</b
                ><br />
                {{ citizen.employment.employerCity }}
                {{ citizen.employment.employerState }}<br />
                Hire Date
                <b>{{ formatDate(citizen.employment.hireDate) }}</b>
                End Date <b>{{ formatDate(citizen.employment.endDate) }}</b>
              </td>
            </tr>
            <tr class="atop">
              <td class="text-right" :width="colWidth">Subsidies</td>
              <td>
                <div class="font-weight-bold">
                  <div v-if="citizen.subsidies.ssi">
                    {{ subsidies[0].label }}
                  </div>
                  <div v-if="citizen.subsidies.ssdi">
                    {{ subsidies[1].label }}
                  </div>
                  <div v-if="citizen.subsidies.medicaid">
                    {{ subsidies[2].label }}
                  </div>
                  <div v-if="citizen.subsidies.medicare">
                    {{ subsidies[3].label }}
                  </div>
                  <div v-if="citizen.subsidies.veterans">
                    {{ subsidies[4].label }}
                  </div>
                  <div v-if="citizen.subsidies.footStamps">
                    {{ subsidies[5].label }}
                  </div>
                  <div v-if="citizen.subsidies.temporaryNeedyFamilies">
                    {{ subsidies[6].label }}
                  </div>
                  <div v-if="citizen.subsidies.welfare">
                    {{ subsidies[7].label }}
                  </div>
                </div>
                <div v-if="citizen.subsidies.other">
                  Other: <b>{{ citizen.subsidies.other }}</b>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CitizenService from '@/services/CitizenService'
import ActivityService from '@/services/ActivityService'
import options from '@/config/valueOptions.json'
import states from '@/config/states'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Demographics',
  components: { Snackbar },
  props: {
    citizen: Object,
    agencyAbbr: String,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      // me otoo
      // dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      // dateFormattedDisplay: vm.formatDate(
      //   new Date().toISOString().substr(0, 10)
      // ),
      hireDate: '',
      hireDateFormatted: '',
      hireDateFormattedDisplay: '',
      endDate: '',
      endDateFormatted: '',
      endDateFormattedDisplay: '',
      hireMenu: '',
      panel: [0],
      states: states,
      colWidth: 120,
      dateFormatted: '',
      dateFormattedDisplay: '',
      dateMenu: false,
      // tab: null,
      dialog: false,
      citizenDialog: false,
      citizenDialogDelete: false,
      citizenIndex: -1,
      citizenId: this.$route.params.id,
      citizenEdit: {
        race: {},
        veteran: {},
        language: {},
        housing: {},
        employment: {},
        subsidies: {}
      },
      rules: [v => !!v || 'This is required'],
      genderOptions: options.gender,
      raceOptions: options.race,
      maritalStatusOptions: options.maritalStatus,
      housingOptions: options.housingStatus,
      employmentOptions: options.employmentStatus,
      studentOptions: options.student,
      ethnicityLabel: options.ethnicity,
      approvedDisabilityLabel: options.approvedDisability,
      subsidiesList: [],
      subsidies: options.subsidies,
      // subsidies: JSON.parse('"{' + options.subsidies + '}"'),
      headers: [
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          align: 'start',
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
      // activities: activities
    }
  },
  watch: {
    citizenDialog(val) {
      // this.isEdit
      val || this.closeCitizenDialog()
    },
    citizenDialogDelete(val) {
      val || this.closeCitizenDelete()
    },
    date(val) {
      if (val) {
        this.dateFormatted = this.formatDate(this.date)
      }
    },
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  created() {
    // console.log(this.$options.name)
  },
  mounted() {
    // this.dateFormatted =
    // const dt = DateTime.now()
    // // const str = dt.toISOString()
    // console.log(dt)
    // this.citizen.dateOfBirth = this.citizen.dateOfBirth.substring(0, 10)
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    dobFormatted() {
      const dt = DateTime.now()
      // const str = dt.toISOString()
      // console.log(str)
      return dt.toISOString()
      // return this.citizen.dateOfBirth.substring(0, 10)
    },
    subsidiesArray() {
      // var formatted = []
      // for (let i = 0; i < this.options.subsidies.length; i++) {
      //   formatted[i] = { id: i, name: i }
      // }
      return options.subsidies
      // return this.citizen.subsidies.ssi
      //   ? 'SSI'
      //   : '' & this.citizen.subsidies.ssdi
      //   ? ', SSDI'
      //   : '' & this.citizen.subsidies.medicaid
      //   ? ', Medicaid'
      //   : ''
    },
    // dateFormatted() {
    //   const dt = DateTime.now(this.citizen.dateOfBirth)
    //   const str = dt.toISOString()
    //   console.log(str)
    //   return dt.toISOString()
    //   // return this.citizen.dateOfBirth.substring(0, 10)
    // },
    // dateFormattedDisplay() {
    //   const dt = DateTime.now(this.citizen.dateOfBirth)
    //   const str = dt.toISOString()
    //   console.log(str)
    //   return dt.toISOString()
    //   // return this.citizen.dateOfBirth.substring(0, 10)
    // },
    formTitle() {
      return this.citizenIndex === -1 ? 'New Citizen' : 'Edit Citizen'
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    }
  },
  methods: {
    save(date) {
      this.$refs.dateMenu.save(date)
      this.dateFormattedDisplay = this.formatDate(date)
    },
    saveHire(date) {
      this.hireDateFormattedDisplay = this.formatDate(date)
    },
    saveEnd(date) {
      this.endDateFormattedDisplay = this.formatDate(date)
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDatePicker(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    age(dateOfBirth) {
      const today = new Date()
      // const dob = this.citizen.dateOfBirth
      const dob = DateTime.fromISO(dateOfBirth)
      // console.log(dob)
      let age = today.getFullYear() - dob.toFormat('yyyy')
      const m = today.getMonth() - dob.toFormat('mm')
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age = age - 1
      }
      return age
    },
    editCitizen() {
      // alert(id)
      // this.citizenIndex = this.citizens.indexOf(item)
      // this.citizen = Object.assign({}, item)
      // this.citizenId = id
      this.citizenEdit = Object.assign({}, this.citizen)
      this.citizenDialog = true

      this.dateFormatted = this.formatDatePicker(this.citizen.dateOfBirth)
      this.dateFormattedDisplay = this.formatDate(this.citizen.dateOfBirth)

      this.hireDateFormatted = this.formatDatePicker(
        this.citizen.employment.hireDate
      )
      this.hireDateFormattedDisplay = this.formatDate(
        this.citizen.employment.hireDate
      )
      this.endDateFormatted = this.formatDatePicker(
        this.citizen.employment.endDate
      )
      this.endDateFormattedDisplay = this.formatDate(
        this.citizen.employment.endDate
      )
    },
    deleteCitizen(item, id) {
      // alert(id)
      this.citizenIndex = this.citizens.indexOf(item)
      this.citizen = Object.assign({}, item)
      this.citizenId = id
      this.citizenDialogDelete = true
    },
    async saveCitizen() {
      const data = {
        dateOfBirth: this.dateFormatted,
        maritalStatus: this.citizenEdit.maritalStatus,
        gender: this.citizenEdit.gender,
        sid: this.citizenEdit.sid,
        gdc: this.citizenEdit.gdc,
        race: {
          label: this.citizenEdit.race.label,
          other: this.citizenEdit.race.other
        },
        ethnicity: this.citizenEdit.ethnicity,
        approvedDisability: this.citizenEdit.approvedDisability,
        veteran: {
          isVet: this.citizenEdit.veteran.isVet,
          branchOfService: this.citizenEdit.veteran.branchOfService,
          activeDuty: this.citizenEdit.veteran.activeDuty
        },
        numberOfDependents: this.citizenEdit.numberOfDependents,
        numberCustodyChildren: this.citizenEdit.numberCustodyChildren,
        housing: {
          status: this.citizenEdit.housing.status,
          other: this.citizenEdit.housing.other
        },
        employment: {
          status: this.citizenEdit.employment.status,
          student: this.citizenEdit.employment.student,
          employerName: this.citizenEdit.employment.employerName,
          employerCity: this.citizenEdit.employment.employerCity,
          employerState: this.citizenEdit.employment.employerState,
          hireDate: this.hireDateFormatted,
          endDate: this.endDateFormatted,
          wage: this.citizenEdit.employment.wage,
          unemploymentBenefits: this.citizenEdit.employment.unemploymentBenefits
        },
        subsidies: {
          ssi: this.citizenEdit.subsidies.ssi ? true : false,
          ssdi: this.citizenEdit.subsidies.ssdi ? true : false,
          medicaid: this.citizenEdit.subsidies.medicaid ? true : false,
          medicare: this.citizenEdit.subsidies.medicare ? true : false,
          veterans: this.citizenEdit.subsidies.veterans ? true : false,
          foodStamps: this.citizenEdit.subsidies.foodStamps ? true : false,
          temporaryNeedyFamilies: this.citizenEdit.subsidies
            .temporaryNeedyFamilies
            ? true
            : false,
          welfare: this.citizenEdit.subsidies.welfare ? true : false,
          other: this.citizenEdit.subsidies.other
        }
      }
      try {
        const citizenUpdate = await CitizenService.update(this.citizen.id, data)
        // console.log(citizenUpdate)
        if (citizenUpdate.status != 200) {
          return
        }

        /*
          activity log - since it worked, send activity
        */
        const activityData = {
          action: 'Updated demographics',
          agency: this.$store.state.agency.id,
          citizen: this.citizen.id,
          citizenName:
            this.citizenEdit.firstName + ' ' + this.citizenEdit.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        // this.$root.$emit('eventing', data);
        // this.$root.$emit('updateActivity', activityData)
        if (activity.status != 200) {
          return
        }
        Object.assign(this.citizen, this.citizenEdit)
        this.citizen.dateOfBirth = this.dateFormatted
        this.citizen.employment.hireDate = this.hireDateFormatted
        this.citizen.employment.endDate = this.endDateFormatted
        this.closeCitizenDialog()
        this.snackbar.model = true
        this.snackbar.message = this.$options.name + ' Citizen updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Citizen problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeCitizenDialog() {
      this.citizenDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   this.citizenIndex = -1
      // })
    },
    closeCitizenDelete() {
      this.citizenDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
