<template>
  <div>
    <v-row dense>
      <v-col
        ><v-btn icon small @click="refreshActivityList()">
          <vue-fontawesome small :icon="['fas', 'sync']" class="mr-1" /> </v-btn
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          dense
          :headers="headers"
          :items="activities"
          :sort-by="['createdAt']"
          :sort-desc="[true]"
          class="elevation-1"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ formatDateTime(item.createdAt) }}
          </template>
          <template v-if="isDev" v-slot:item.actions="{ item }">
            <v-btn icon small @click="deleteActivity(item)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActivityService from '@/services/ActivityService'
// import Snackbar from '@/components/Snackbar.vue'
import { DateTime } from 'luxon'

export default {
  name: 'CitizenLog',
  props: {
    citizen: String
  },
  data() {
    return {
      activities: [],
      activityIndex: '',
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      headers: [
        {
          text: 'User',
          align: 'start',
          sortable: true,
          value: 'userName'
        },
        {
          text: 'Agency',
          align: 'start',
          sortable: true,
          value: 'agency.name'
        },
        {
          text: 'Action',
          align: 'start',
          sortable: true,
          value: 'action'
        },
        {
          text: 'Date',
          align: 'start',
          value: 'createdAt',
          sortable: true
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
      // activities: activities
    }
  },
  created() {
    // console.log(process.env.NODE_ENV)
    // console.log('citizen:' + this.citizen)
    ActivityService.getActivityByCitizen(this.citizen)
      .then(response => {
        this.activities = response.data
        console.log(this.activities)
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
  },
  methods: {
    formatDateTime(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toLocal()
        .toFormat('MM/dd/yyyy hh:mm a')
      return dt
    },
    async refreshActivityList() {
      try {
        const activityResult = await ActivityService.getActivityByCitizen(
          this.citizen
        )
        if (!activityResult) {
          return
        }
        this.activities = activityResult.data
      } catch (error) {
        console.log(error)
      }
    },
    async deleteActivity(item) {
      const id = item.id
      this.activityIndex = this.activities.indexOf(item)
      // console.log(this.activityIndex)
      try {
        const activityResult = await ActivityService.delete(id)
        if (!activityResult) {
          return
        }
        // this.snackbar.model = true
        // this.snackbar.message = 'Activity deleted'
        // this.snackbar.color = 'green'
        // this.snackbar.icon = ['far', 'check']
        this.activities.splice(this.activityIndex, 1)
      } catch (error) {
        // this.snackbar.model = true
        // this.snackbar.message = error
        // this.snackbar.color = 'red'
        // this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(error)
      }
    }
  },
  // mounted() {
  //   this.$root.$on('updateActivity', data => {
  //     console.log(data)
  //   })
  // },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isDev() {
      return this.$store.state.user.isDev || false
    }
    // currentEnv() {
    //   return process.env.NODE_ENV || false
    // }
    // formatDateTime(date) {
    //   if (!date) return null
    //   const dt = DateTime.fromISO(date)
    //     .toLocal()
    //     .toFormat('MM/dd/yyyy hh:mm a')
    //   return dt
    // }
  }
}
</script>
<style lang="scss" scoped></style>
