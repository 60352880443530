<template>
  <div>
    <snackbar :attr="snackbar" />
    <v-dialog v-model="citizenDialog" max-width="800px">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>Contact Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeCitizenDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-expansion-panels accordion multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>General</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="citizenEdit.firstName"
                      required
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      hide-details="auto"
                    ></v-text-field>
                    <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="citizenEdit.middleName"
                      id="middleName"
                      name="middleName"
                      label="Middle Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="citizenEdit.lastName"
                      :rules="rules"
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model.trim="citizenEdit.email"
                      :rules="emailRules"
                      id="email"
                      name="email"
                      label="Email"
                      hide-details="auto"
                    ></v-text-field>
                    <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.homePhone"
                      v-mask="'(###) ###-####'"
                      id="homePhone"
                      name="homePhone"
                      label="Home Phone"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.mobilePhone"
                      v-mask="'(###) ###-####'"
                      :rules="rules"
                      id="mobilePhone"
                      name="mobilePhone"
                      label="Mobile Phone"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="citizenEdit.preferredContactMethod"
                      :items="preferredContactMethodOptions"
                      label="Preferred contact Method"
                      hide-details="auto"
                      :rules="rules"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Address</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="citizenEdit.address.facility"
                      id="facility"
                      name="facility"
                      label="Facility (if applicable)"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.address.address1"
                      required
                      id="address1"
                      name="address1"
                      label="Address 1"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.address.address2"
                      id="address2"
                      name="address2"
                      label="Address 2"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="citizenEdit.address.city"
                      id="city"
                      name="city"
                      label="City"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-select
                      outlined
                      v-model="citizenEdit.address.state"
                      :items="states"
                      item-value="abbreviation"
                      item-text="name"
                      label="State"
                      hide-details="auto"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="citizenEdit.address.zip"
                      id="zip"
                      name="zip"
                      label="Zip"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-divider></v-divider>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Emergency Contact</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.emergencyContact.firstName"
                      required
                      id="efirstName"
                      name="efirstName"
                      label="First Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.emergencyContact.lastName"
                      required
                      id="elastName"
                      name="elastName"
                      label="Last Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="citizenEdit.emergencyContact.relation"
                      required
                      label="Relation"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model.trim="citizenEdit.emergencyContact.email"
                      :rules="emailRules"
                      id="eemail"
                      name="eemail"
                      label="Email"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="citizenEdit.emergencyContact.mobilePhone"
                      v-mask="'(###) ###-####'"
                      id="emobilePhone"
                      name="emobilePhone"
                      label="Mobile Phone"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeCitizenDialog"> Close </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            class="mr-4"
            @click="saveCitizen"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex flex-row">
        <v-btn
          v-if="canEdit"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editCitizen()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn>
        <!-- <v-btn
          v-if="agencyAbbr == 'ars' || isAdmin"
          small
          dark
          class="primary-btn"
          elevation="0"
          @click="editCitizen()"
        >
          <vue-fontawesome :icon="['fas', 'pencil-alt']" class="mr-1" />
          Edit
        </v-btn> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right" :width="leftColWidth">Home Phone</td>
              <td class="font-weight-bold">
                {{ citizen.homePhone }}
                <vue-fontawesome
                  v-if="citizen.preferredContactMethod == 'Home'"
                  :icon="['far', 'check']"
                  class="ml-1"
                />
              </td>
            </tr>
            <tr>
              <td class="text-right" align="top" :width="leftColWidth">
                Mobile Phone
              </td>
              <td class="font-weight-bold">
                {{ citizen.mobilePhone }}
                <vue-fontawesome
                  v-if="citizen.preferredContactMethod == 'Mobile'"
                  :icon="['far', 'check']"
                  class="ml-1"
                />
              </td>
            </tr>
            <tr>
              <td class="text-right text-no-wrap" :width="leftColWidth">
                Email
              </td>
              <td class="font-weight-bold">
                {{ citizen.email }}
                <!-- {{ citizenEdit.emergencyContact.firstName }} -->
                <vue-fontawesome
                  v-if="citizen.preferredContactMethod == 'Email'"
                  :icon="['far', 'check']"
                  class="ml-1"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-right" align="top" :width="rightColWidth">
                Facility
              </td>
              <td class="font-weight-bold">
                <div v-if="citizen.address.facility">
                  {{ citizen.address.facility }}
                </div>
                <div v-else>
                  <vue-fontawesome :icon="['far', 'ban']" class="ml-1" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-right" :width="rightColWidth">Address</td>
              <td class="font-weight-bold">
                {{ citizen.address.address1 }} {{ citizen.address.address2 }}
                {{ citizen.address.city }}, {{ citizen.address.state }},
                {{ citizen.address.zip }}
              </td>
            </tr>
            <tr class="atop">
              <td class="text-right" align="top" :width="rightColWidth">
                Emergency Contact
              </td>
              <td>
                <span class="font-weight-bold">
                  {{ citizen.emergencyContact.firstName }}
                  {{ citizen.emergencyContact.lastName }}
                </span>
                <span
                  v-if="citizen.emergencyContact.relation"
                  class="text--secondary"
                  >({{ citizen.emergencyContact.relation }})</span
                ><br />
                {{ citizen.emergencyContact.mobilePhone
                }}<span
                  v-if="
                    citizen.emergencyContact.mobilePhone &&
                      citizen.emergencyContact.email
                  "
                >
                  |
                </span>
                {{ citizen.emergencyContact.email }}
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CitizenService from '@/services/CitizenService'
import ActivityService from '@/services/ActivityService'
import options from '@/config/valueOptions'
import states from '@/config/states'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  components: { Snackbar },
  name: 'ContactDetail',
  props: {
    citizen: Object,
    agencyAbbr: String,
    canEdit: Boolean
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      // dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      // dateFormattedDisplay: vm.formatDate(
      //   new Date().toISOString().substr(0, 10)
      // ),
      panel: [0],
      states: states,
      leftColWidth: 120,
      rightColWidth: 160,
      dateFormatted: '',
      dateFormattedDisplay: '',
      dateMenu: false,
      tab: null,
      dialog: false,
      citizenDialog: false,
      citizenDialogDelete: false,
      citizenIndex: -1,
      citizenId: this.$route.params.id,
      // formattedDob: new Date(citizen.dateOfBirth) | dateFormat('YYYY-MM-DD'),
      // citizen: {},
      // citizen: {
      //   sid: '',
      //   firstName: '',
      //   lastName: '',
      //   dateOfBirth: null,
      //   gender: '',
      //   race: {
      //     label: '',
      //     other: ''
      //   }
      // },
      citizenEdit: {
        address: {},
        emergencyContact: {}
      },
      // citizenEdit: {
      //   emergencyContact: {
      //     firstName: '',
      //     lastName: '',
      //     relation: '',
      //     mobilePhone: '',
      //     email: ''
      //   }
      // },
      // citizenEdit: {
      //   email: '',
      //   homePhone: '',
      //   mobilePhone: '',
      //   preferredContactMethod: '',
      //   emergencyFirstName: '',
      //   emergencyLastName: '',
      //   emergencyRelationship: '',
      //   emergencyMobilePhone: '',
      //   emergencyEmail: ''
      // },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      preferredContactMethodOptions: options.preferredContactMethod,
      headers: [
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'name'
        },
        {
          text: 'Gender',
          align: 'start',
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
      // activities: activities
    }
  },
  watch: {
    citizenDialog(val) {
      // this.isEdit
      val || this.closeCitizenDialog()
    },
    citizenDialogDelete(val) {
      val || this.closeCitizenDelete()
    },
    date(val) {
      if (val) {
        this.dateFormatted = this.formatDate(this.date)
      }
    },
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  mounted() {},
  computed: {
    isAdmin() {
      return this.$store.state.user.isAdmin || false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    dobFormatted() {
      const dt = DateTime.now()
      // const str = dt.toISOString()
      // console.log(str)
      return dt.toISOString()
      // return this.citizen.dateOfBirth.substring(0, 10)
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    }
    // agencyAbbr() {
    //   return this.$store.state.user.agency.abbr || null
    // }
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
      // const dt = DateTime.fromISO(this.citizen.dateOfBirth)
      // const str = dt.toISODate()
      // console.log('formatDate: ' + str)

      return dt.toISODate()
    },
    editCitizen() {
      // alert(id)
      // this.citizenIndex = this.citizens.indexOf(item)
      // this.citizen = Object.assign({}, item)
      // this.citizenId = id
      this.citizenEdit = Object.assign({}, this.citizen)
      this.citizenDialog = true
      // var dt = DateTime.fromISO(this.citizen.dateOfBirth)
      // const str = dt.toISODate()
      // console.log(str)
      // this.dateFormattedDisplay = str
      // console.log(this.citizen)
    },
    deleteCitizen(item, id) {
      // alert(id)
      this.citizenIndex = this.citizens.indexOf(item)
      this.citizen = Object.assign({}, item)
      this.citizenId = id
      this.citizenDialogDelete = true
    },
    // deleteCitizenConfirm() {
    //   const id = this.citizenId
    //   // alert(id)
    //   CitizenService.delete(id)
    //     .then(response => {
    //       console.log(response)
    //       this.citizens.splice(this.citizenIndex, 1)
    //       this.closeCitizenDelete()
    //     })
    //     .catch(e => {
    //       console.log(e)
    //     })
    // },
    async saveCitizen() {
      // alert('component')
      const data = {
        firstName: this.citizenEdit.firstName,
        middleName: this.citizenEdit.middleName,
        lastName: this.citizenEdit.lastName,
        email: this.citizenEdit.email,
        homePhone: this.citizenEdit.homePhone,
        mobilePhone: this.citizenEdit.mobilePhone,
        preferredContactMethod: this.citizenEdit.preferredContactMethod,
        address: {
          facility: this.citizenEdit.address.facility,
          address1: this.citizenEdit.address.address1,
          address2: this.citizenEdit.address.address2,
          unit: this.citizenEdit.address.unit,
          city: this.citizenEdit.address.city,
          state: this.citizenEdit.address.state,
          zip: this.citizenEdit.address.zip
        },
        emergencyContact: {
          firstName: this.citizenEdit.emergencyContact.firstName,
          lastName: this.citizenEdit.emergencyContact.lastName,
          relation: this.citizenEdit.emergencyContact.relation,
          mobilePhone: this.citizenEdit.emergencyContact.mobilePhone,
          email: this.citizenEdit.emergencyContact.email
        },
        updatedBy: this.$store.state.user.id
      }
      try {
        const citizenUpdate = await CitizenService.update(this.citizen.id, data)
        console.log('update')
        // console.log(citizenUpdate)
        if (citizenUpdate.status != 200) {
          return
        }
        // since it worked, send activity log
        const activityData = {
          action: 'Updated contact details',
          agency: this.$store.state.agency.id,
          citizen: this.citizen.id,
          citizenName:
            this.citizenEdit.firstName + ' ' + this.citizenEdit.lastName,
          userName:
            this.$store.state.user.firstName +
            ' ' +
            this.$store.state.user.lastName,
          createdBy: this.$store.state.user.id
        }
        const activity = await ActivityService.create(activityData)
        if (activity.status != 200) {
          return
        }
        Object.assign(this.citizen, this.citizenEdit)
        this.closeCitizenDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Citizen Contact Info updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Citizen problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    },
    closeCitizenDialog() {
      this.citizenDialog = false
      // this.$nextTick(() => {
      //   this.citizen = Object.assign({}, this.citizenDefault)
      //   // this.citizenIndex = -1
      // })
    },
    closeCitizenDelete() {
      this.citizenDialogDelete = false
      this.$nextTick(() => {
        this.citizen = Object.assign({}, this.citizenDefault)
        this.citizenIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
